<template>
  <div
    class="flex justify-between items-center"
    @click="
      () => {
        if (!disabled) {
          toggled = !toggled
          $emit('toggle', toggled)
        }
      }
    "
  >
    <p v-if="title" class="text-sm">{{ title }}</p>
    <slot />
    <div
      class="w-12 h-7 flex items-center rounded-full p-0.5 duration-100 ease-in-out flex-shrink-0 ml-1"
      :class="
        toggled && disabled
          ? 'bg-green-600 opacity-50'
          : toggled
          ? 'bg-green-600'
          : disabled
          ? 'bg-gray-300 opacity-50'
          : 'bg-gray-300'
      "
    >
      <div
        class="bg-white w-6 h-6 rounded-full transform duration-100 ease-in-out"
        :class="toggled && 'translate-x-5'"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      toggled: false
    }
  },
  watch: {
    value(val) {
      this.toggled = val
    }
  },
  mounted() {
    this.toggled = this.value
    this.$emit('toggle', this.toggled)
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
