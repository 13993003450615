var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-between items-center",on:{"click":function () {
      if (!_vm.disabled) {
        _vm.toggled = !_vm.toggled
        _vm.$emit('toggle', _vm.toggled)
      }
    }}},[(_vm.title)?_c('p',{staticClass:"text-sm"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_vm._t("default"),_c('div',{staticClass:"w-12 h-7 flex items-center rounded-full p-0.5 duration-100 ease-in-out flex-shrink-0 ml-1",class:_vm.toggled && _vm.disabled
        ? 'bg-green-600 opacity-50'
        : _vm.toggled
        ? 'bg-green-600'
        : _vm.disabled
        ? 'bg-gray-300 opacity-50'
        : 'bg-gray-300'},[_c('div',{staticClass:"bg-white w-6 h-6 rounded-full transform duration-100 ease-in-out",class:_vm.toggled && 'translate-x-5'})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }